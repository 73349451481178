import { MarketplaceProductStatusEnum } from '~/modules/marketplacesManagement/enums/MarketplaceProductStatusEnum';

interface IAppsSettingsQueries {
  company?: string;
  openModal?: boolean;
  redirectTo?: string;
}

interface IUpdatePOSFacilQueries {
  redirectTo?: string;
}

export const routes = {
  auth: {
    signIn: '/',
    signUp: '/signup',
    help: '/help',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
  },
  setup: '/setup',
  home: '/home',
  checkout: {
    plans: (companyId: string): string =>
      `/companies/${companyId}/checkout/plans`,
    payment: (companyId: string): string =>
      `/companies/${companyId}/checkout/payment`,
  },
  orders: {
    list: '/orders',
    details: (id: string): string => `/orders/${id}`,
  },
  sales: {
    reports: '/sales/reports',
  },
  abandonedCarts: {
    list: '/abandoned-carts',
    details: (id: string): string => `/abandoned-carts/${id}`,
  },
  companies: {
    list: '/companies',
    create: '/companies/create',
    update: (id: string): string => `/companies/${id}`,
  },
  taxationRules: {
    list: '/taxation-rules',
    create: '/taxation-rules/create',
    update: (id: string): string => `/taxation-rules/${id}`,
  },
  cashiers: {
    list: '/cashiers',
    details: (id: string): string => `/cashiers/${id}`,
    reports: '/cashiers/reports',
  },
  collectsControl: '/collects-control',
  devices: {
    list: '/devices',
  },
  categories: {
    list: '/categories',
    create: '/categories/create',
    update: (id: string): string => `/categories/${id}`,
    subcategories: {
      create: (categoryId: string): string =>
        `/categories/${categoryId}/create`,
      update: (categoryId: string, subcategoryId: string): string =>
        `/categories/${categoryId}/${subcategoryId}`,
    },
  },
  promotions: {
    list: '/promotions',
    create: '/promotions/create',
    update: (id: string): string => `/promotions/${id}`,
  },
  events: {
    list: '/events',
    create: '/events/create',
    update: (id: string): string => `/events/${id}`,
  },
  products: {
    list: '/products',
    create: '/products/create',
    reports: '/products/reports',
    batchUpdate: '/products/batch-update',
    priceLists: '/products/price-lists',
    productsImport: '/products/imports',
    importDetails: (id: string): string => `/products/imports/${id}`,
    priceListDetails: (id: string): string => `/products/price-lists/${id}`,
    details: (id: string): string => `/products/${id}`,
    update: (id: string, redirectTo?: string): string =>
      `/products/${id}/${
        redirectTo && redirectTo !== 'undefined'
          ? `?redirect_to=${redirectTo}`
          : ''
      }`,
    environments: {
      list: '/products/environments',
    },
  },
  customFields: {
    list: '/custom-fields',
  },
  users: {
    list: '/users',
    groups: {
      list: '/users/groups',
    },
  },
  apps: {
    settings: (tag: string, queries?: IAppsSettingsQueries): string => {
      return `/apps/${tag}${
        queries?.company ? `?company=${queries.company}` : ''
      }${
        queries?.openModal
          ? `${queries?.company ? '&' : '?'}openModal=true`
          : ''
      }${
        queries?.redirectTo && queries?.redirectTo !== 'undefined'
          ? `${queries?.company || queries?.openModal ? '&' : '?'}redirect_to=${
              queries.redirectTo
            }`
          : ''
      }`;
    },
    list: (redirectTo?: string): string =>
      `/apps${
        !!redirectTo && redirectTo !== 'undefined'
          ? `?redirect_to=${redirectTo}`
          : ''
      }`,
    menuFacil: {
      create: (redirectTo?: string): string =>
        `/apps/menu-facil/create${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string): string => `/apps/menu-facil/${id}`,
    },
    kdsFacil: {
      create: (redirectTo?: string): string =>
        `/apps/kds-facil/create${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string): string => `/apps/kds-facil/${id}`,
    },
    passwordPanel: {
      create: (redirectTo?: string): string =>
        `/apps/painel-de-senha/create${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string, redirectTo?: string): string =>
        `/apps/painel-de-senha/${id}${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
    },
    mercadoPago: {
      create: (redirectTo?: string): string =>
        `/apps/mercado-pago/save${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string, redirectTo?: string): string =>
        `/apps/mercado-pago/save?companyId=${id}${
          redirectTo && redirectTo !== 'undefined'
            ? `&redirect_to=${redirectTo}`
            : ''
        }`,
    },
    uaiRango: {
      create: (redirectTo?: string): string =>
        `/apps/uai-rango/create${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string): string => `/apps/uai-rango/${id}`,
    },
    posFacil: {
      update: (id: string, queries?: IUpdatePOSFacilQueries): string =>
        `/apps/pos-facil-mobile/${id}${
          queries?.redirectTo && queries?.redirectTo !== 'undefined'
            ? `?redirect_to=${queries.redirectTo}`
            : ''
        }`,
    },
    pagaRapido: {
      create: '/apps/paga-rapido/create',
      update: (id: string): string => `/apps/paga-rapido/${id}`,
    },
    praVendas: {
      create: (redirectTo?: string): string =>
        `/apps/pra-vendas/create${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string): string => `/apps/pra-vendas/${id}`,
    },
    vitrineTotem: {
      create: (redirectTo: string): string =>
        `/apps/totem/create${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string): string => `/apps/totem/${id}`,
    },
    printService: {
      create: (redirectTo: string): string =>
        `/apps/print-service/create${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string): string => `/apps/print-service/${id}`,
    },
    pdvFacil: {
      list: '/apps/pdv-facil',
      create: '/apps/pdv-facil/create',
      update: (id: string): string => `/apps/pdv-facil/${id}`,
    },
    autopagFacil: {
      list: '/apps/autopag-facil',
      create: '/apps/autopag-facil/create',
      update: (id: string): string => `/apps/autopag-facil/${id}`,
    },
    tabletFacil: {
      list: '/apps/tablet-facil',
      create: '/apps/tablet-facil/create',
      update: (id: string): string => `/apps/tablet-facil/${id}`,
    },
    pdvGoldVarejo: {
      list: '/apps/pdv-gold-varejo',
      create: '/apps/pdv-gold-varejo/create',
      update: (id: string): string => `/apps/pdv-gold-varejo/${id}`,
    },
    pdvGoldChef: {
      list: '/apps/pdv-gold-chef',
      create: '/apps/pdv-gold-chef/create',
      update: (id: string): string => `/apps/pdv-gold-chef/${id}`,
    },
    autoPeso: {
      list: '/apps/auto-peso',
      create: '/apps/auto-peso/create',
      update: (id: string): string => `/apps/auto-peso/${id}`,
    },
    appLojista: {
      create: (redirectTo?: string): string =>
        `/apps/app-lojista/create${
          redirectTo && redirectTo !== 'undefined'
            ? `?redirect_to=${redirectTo}`
            : ''
        }`,
      update: (id: string): string => `/apps/app-lojista/${id}`,
    },
  },
  subscriptions: {
    list: '/subscriptions',
    details: (id: string): string => `/subscriptions/${id}`,
  },
  stockLocals: {
    list: '/stock-locals',
    reports: '/stock-locals/reports',
  },
  stockAdjustments: {
    list: (id?: string, name?: string): string =>
      `/stock-adjustments${id && id !== 'undefined' ? `?product=${id}` : ''}${
        name && name !== 'undefined' ? `&name=${name}` : ''
      }`,
  },
  brands: {
    list: '/brands',
  },
  stockMonitoring: {
    list: '/stock-monitoring',
    details: (stockLocalId: string): string =>
      `/stock-monitoring/${stockLocalId}`,
  },
  stockTransferGroups: {
    list: '/stock-transfer-groups',
    create: '/stock-transfer-groups/create',
    update: (id: string): string => `/stock-transfer-groups/${id}`,
  },
  customers: {
    list: '/customers',
    create: '/customers/create',
    update: (id: string): string => `/customers/${id}`,
  },
  sellers: {
    list: '/sellers',
    create: '/sellers/create',
    update: (id: string): string => `/sellers/${id}`,
  },
  ordersPosts: {
    list: '/orders-posts',
    orders: (batchId: string, query): string =>
      `/orders-posts/${batchId}${query}`,
  },
  marketplacesManagement: {
    list: (redirectTo?: string): string =>
      `/marketplaces-management${
        redirectTo && redirectTo !== 'undefined'
          ? `?redirect_to=${redirectTo}`
          : ''
      }`,
    products: (data: {
      marketplaceId: string;
      configurationId: string;
      defaultStatus?: MarketplaceProductStatusEnum;
      redirectTo?: string;
    }): string =>
      `/marketplaces-management/${data.marketplaceId}/products/${
        data.defaultStatus
          ? `?status=${data.defaultStatus}&configuration=${data.configurationId}`
          : `?configuration=${data.configurationId}`
      }${
        data?.redirectTo && data.redirectTo !== 'undefined'
          ? `&redirect_to=${data.redirectTo}`
          : ''
      }`,
  },
  banking: {
    loans: {
      list: '/banking/loans',
      create: '/banking/loans/create',
      update: (id: string): string => `/banking/loans/${id}`,
    },
    transactions: {
      list: '/banking/transactions',
    },
    recharges: {
      list: '/banking/recharges',
    },
    billPayments: {
      list: '/banking/bill-payments',
    },
  },
  fileTransmissions: {
    board: (companyId?: string): string =>
      !companyId
        ? `/file-transmissions`
        : `/file-transmissions?initialCompany=${companyId}`,
    xml: {
      batches: (company: string): string =>
        `/file-transmissions/xml?company=${company}`,
      list: (xmlBatchId: string, companyQuery: string): string =>
        `/file-transmissions/xml/${xmlBatchId}${companyQuery}`,
    },
    app: (appConfigurationId: string): string =>
      `/file-transmissions/${appConfigurationId}`,
  },
  catalogs: {
    list: '/catalogs',
    create: '/catalogs/create',
    update: (catalogId: string): string => `/catalogs/${catalogId}`,
  },
  audits: {
    permissionsGranted: {
      list: '/audit/permissions-granted',
    },
  },
  contract: '/contract',
};
